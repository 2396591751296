import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

window.Swal = Swal;

// Behavior after click to confirm button
const confirmed = (element, result) => {
  if (result) {
    // はいが押された場合、confirm属性を外してから再度ボタンをクリック
    element.removeAttribute('data-confirm');
    element.click();
  }
};

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
  // console.log('Hello world!');
  const message = element.getAttribute('data-confirm');
  const text = element.getAttribute('data-text');

  Swal.fire({
    title: message || '削除してもよろしいですか？',
    html: text || '削除を実行すると復元できません',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'はい',
    cancelButtonText: 'キャンセル',
    closeOnConfirm: true,
    reverseButtons: true

  }).then(function (result) {
    if (result.value) {
      confirmed(element, result);
    }
  });

};

const allowAction = (element) => {
  if (element.getAttribute('data-confirm') === null) {
    return true
  }

  showConfirmationDialog(element);
  return false;
};

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

Rails.delegate(document, 'a[data-confirm], input[data-confirm], button[data-confirm]', 'click', handleConfirm)

