import { Controller } from "stimulus";
// import $ from 'jquery';
import bsCustomFileInput from "bs-custom-file-input";

export default class extends Controller {
  connect() {
    // console.log('connected');

    bsCustomFileInput.init();
    document
      .getElementById("inputFileReset")
      .addEventListener("click", function () {
        bsCustomFileInput.destroy();
        var elem = document.getElementById("case_document_pdf_file");
        elem.value = "";
        elem.dispatchEvent(new Event("change"));

        bsCustomFileInput.init();
      });
  }
}
