import { Controller } from "stimulus";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
// import Select2 from "select2"
import select2 from "select2";

export default class extends Controller {
  connect() {
    // Select2()
    $(".select2").select2();
    // console.log('select2 connected')
  }
}
