
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "common/direct_uploads.js"
import "channels"

Rails.start()
ActiveStorage.start()

import "controllers"


import "core-js/stable";
import "regenerator-runtime/runtime";
import 'events-polyfill';


window.$ = jQuery;


import '@fortawesome/fontawesome-free/js/all.min.js'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js';
// import 'admin-lte/dist/js/adminlte.min.js';

import 'common/sweet-alert-confirm';  // confirmをsweetalert2でオーバーライド
global.toastr = require("toastr")

import '../stylesheets/login_layout.scss' // 開発時はこれだけで動く

